/*
ORANGE:     #F4764D
BLUE: 	    #0091BA
OFFBLUE-HL  #007596
OFFBLUE-BG: #91AFB7

*/

html {
    font-family: 'Open Sans', sans-serif;
    background-color: #0091BA;
    font-size: 10pt;
    padding: 0px;
    margin: 0px;
    min-height: 100vh;
}
.white-bg {
    background-color: #fff;
}

/* WRAP CONTAINERS */
.app-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-color: #0091BA;
}
.app-main-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
    background-color: gainsboro;
}

.app-container-login {
    background: #0091BA;
    position: relative;
    display: flex;
    min-height: 100vh;
    width: 100%;
}

/* NOTES */
.notes-body {
    height: 70vh;
    overflow: auto;
    place-content: flex-start;
}
.note-card {
    background-color: lightgray;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #007596;
    margin-bottom: .25rem;
    height: fit-content;
}
    .note-card.owner {
        background-color: #91AFB7;
        width:80%;
        margin-left:20%;
    }
    .note-card.other {
        width: 80%;
        margin-right: 20%;
    }
.note-date{
    font-style:italic;
    color:dimgray;
}
.note-name{
    font-size:1.1rem;
    font-weight:700;
}
.note-text {
    font-size: 1.1rem;
    font-weight: 600;
}

/* CONTROLS */
.deaftalk-button {
    background-color: #007596;
    color: #fff;
    border: 0px;
    border-radius: .25rem;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    display: inline-block;
    font-weight: 400;
    transition: 0.3s;
    text-decoration: none;
    cursor: pointer;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

    .deaftalk-button:hover {
        background-color: #0091BA;
    }

    .deaftalk-button:disabled {
        cursor: not-allowed;
    }

    .deaftalk-button:active {
        background-color: #0091BA;
    }

.deaftalk-input-100 {
    background-color: #F2F2F2;
    color: black;
    padding: 8px;
    border-radius: 2px;
    border: 1px solid #E6E6E6;
    transition: 0.3s;
    height: 38px;
    width: 100%;
}
.deaftalk-login-button {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.deaftalk-login-type-button-main {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: table-cell;
    justify-content:center;
    padding:2rem;
}
.deaftalk-login-type-button-secondary {
    background-color: gray !important;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: table-cell;
    justify-content: center;
}
.deaftalk-login-type-button span {
    margin-left:8px;
}

/* SCROLLBAR WEBKIT */
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
}

/* Text adjust */
.dt-rem-1 {
    font-size: 1.1rem;
}
.dt-rem-2 {
    font-size: 1.2rem;
}
.dt-rem-3 {
    font-size: 1.3rem;
}
.dt-rem-4 {
    font-size: 1.4rem;
}
.dt-rem-5 {
    font-size: 1.5rem;
}
/* Spinner */
.dt-spinner-border-sm {
    width: 4rem;
    height: 4rem;
    border: 0.25em solid currentColor;
    border-right-color: transparent;
    margin-left: auto;
    margin-right: auto;
}


/* BOOTSTRAP */
.navbar > .container {
     max-width:95% !important 
}
.show > .nav-link,
.navbar {
    background-color: #007596 !important;
}
.nav-link {
    color: white !important;
}
    .nav-link.active {
        font-weight: 500;
        color: black !important;
        background-color: white !important;
    }
    .nav-link.active:hover {
        background-color: white !important;
    }
    .nav-link:hover {
        background-color: #0091BA !important;
    }
.navbar-expand-lg .navbar-nav .nav-link {
    padding: 1rem;
}
.navbar-nav .dropdown-menu {
    margin-top: 0px;
    margin-bottom: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
}

.dt-table tr:hover {
    background-color: #91AFB7 !important;
}
