
.cs-loader {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: #000000;
    opacity: 0.8;
    z-index: 100;
    overflow: hidden;
}


.cs-loader-inner {
    text-align: center;
    color: white;
    top: 50%;
    position: relative;
}

    .cs-loader-inner label {
        font-size: 20px;
        opacity: 0;
        display: inline-block;
    }

@keyframes lol {
    0% {
        opacity: 0;
        transform: translateX(-300px);
    }

    33% {
        opacity: 1;
        transform: translateX(0px);
    }

    66% {
        opacity: 1;
        transform: translateX(0px);
    }

    100% {
        opacity: 0;
        transform: translateX(300px);
    }
}

@-webkit-keyframes lol {
    0% {
        opacity: 0;
        -webkit-transform: translateX(-300px);
    }

    33% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }

    66% {
        opacity: 1;
        -webkit-transform: translateX(0px);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateX(300px);
    }
}

.cs-loader-inner label:nth-child(6) {
    -webkit-animation: lol 3s infinite ease-in-out;
    animation: lol 2s infinite ease-in-out;
}

.cs-loader-inner label:nth-child(5) {
    -webkit-animation: lol 3s 100ms infinite ease-in-out;
    animation: lol 2s 100ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(4) {
    -webkit-animation: lol 3s 200ms infinite ease-in-out;
    animation: lol 2s 200ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(3) {
    -webkit-animation: lol 3s 300ms infinite ease-in-out;
    animation: lol 2s 300ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(2) {
    -webkit-animation: lol 3s 400ms infinite ease-in-out;
    animation: lol 2s 400ms infinite ease-in-out;
}

.cs-loader-inner label:nth-child(1) {
    -webkit-animation: lol 3s 500ms infinite ease-in-out;
    animation: lol 2s 500ms infinite ease-in-out;
}
